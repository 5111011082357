//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HTMLContent from '~/components/HTMLContent.vue';

export default {
  name: "CategoryContent",
  components: {HTMLContent},
  props: {
    content: {
      type: Object,
    },
  },
};

import { computed, readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useApi } from '~/composables/useApi';
import type {UseBrandsErrors, UseBrandsLoadParams, UseBrandsInterface, BrandType} from './useBrands';
import getBrandsGql from "~/bold/stores/graphql/getBrands.gql";
import getProductBrandInfoQueryGql from "~/bold/stores/graphql/getProductBrandInfoQuery.gql";
import {ProductBrandInformation} from "./useBrands";

/**
 * Get a list of all product manufacturers.
 *
 * See the {@link } for a list of methods and values available in this composable.
 */
export function useBrands(): UseBrandsInterface {
  const { query } = useApi();
  const loading = ref(false);
  const error = ref<UseBrandsErrors>({ load: null });
  const brands = ref<BrandType[]>;
  const brandProductInformation = ref<ProductBrandInformation>;

  const getBrands = async (params?: UseBrandsLoadParams) => {
    error.value.load = null;
    loading.value = true;

    try {
      const { data, errors } = await query( getBrandsGql, { filter: {is_display: {eq: "1"}}, storeId: 1, pageSize: 1000 });

      if (!errors) {
        // @ts-ignore
        brands.value = data;
      }
    } catch (err) {
      Logger.debug('[ERROR] useBrands/load', err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  const getProductBrandInfo = async (params) => {
    error.value.load = null;
    loading.value = true;

    try {
      const { data, errors } = await query(getProductBrandInfoQueryGql, { filter: {sku: {eq: params}}});
      if (!errors) {
        // @ts-ignore
        brandProductInformation.value = data;
      }
    } catch (err) {
      Logger.debug('[ERROR] getProductBrandInfo/load', err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };


  return {
    brands,
    brandProductInformation,
    loading: readonly(computed(() => loading.value)),
    getBrands,
    getProductBrandInfo
  };
}

export * from './useBrands';
export default useBrands;

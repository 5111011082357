//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from "@storefront-ui/vue/src/utilities/directives"
export default {
  name: "BoldChevron",
  directives: {
    focus,
  },
};

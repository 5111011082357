//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink";
import SvgImage from "~/components/General/SvgImage";
import {defineComponent, ref, useContext, computed} from "@nuxtjs/composition-api";

export default defineComponent({
  name: "BoldBreadCrumbs",
  components: {SvgImage},
  inject: {
    components: {
      default: {SfLink},
    },
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    removeLastXBreadcrumbs: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const structuredData = ref({});
    const { localePath, $config } = useContext();

    const baseUrl = $config.baseUrl

    // generate breadcrumb items
    const generateItems = (breadcrumbs) => {
      let items = [];

      breadcrumbs.forEach((breadcrumb, index) => {
        items.push({
            "@type": "ListItem",
            "position": index + 1,
            "name": breadcrumb.text,
            "item": baseUrl + localePath(breadcrumb.link)
          })
      });

      return items
    }

    // add structured
    structuredData.value = computed(() => {
      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: generateItems(props.breadcrumbs),
      };
    });

    return {
        structuredData
      };
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          hid: 'structured-data-breadcrumb',
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json',
        },
      ],
    };
  },
  breadcrumbLast(breadcrumbs) {
    return breadcrumbs.length - 1;
  },
});

export default `
  query getProductBrandInfoQuery($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
    items {
      name
      sku
       mpbrand {
        attribute_id
        brand_id
        default_value
        description
        image 
        is_featured
        meta_description
        meta_keywords
        meta_title
        option_id
        page_title
        short_description
        static_block
        store_id
        url_key
        value
      }
    }
  }
}`;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SvgImage from "~/components/General/SvgImage";
import { useUser } from '~/modules/customer/composables/useUser';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import AddToWishlist from '~/components/AddToWishlist.vue';
import {computed, onBeforeUnmount, onMounted, ref, toRef} from "@nuxtjs/composition-api";

export default {
  name: "BoldShareBar",
  components: {
    SvgImage,
    AddToWishlist,
  },
  props: {
    shareText: {
      type: String,
      default: ""
    },
    shareUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    product: {
      type: Object
    }
  },
  setup(props) {
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const { isAuthenticated } = useUser();

    return {
      addItemToWishlist: addOrRemoveItem,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
    };
  }
};

export default `
  query getBrands($filter: MageplazaBrandsFilterInput, $storeId: Int, $pageSize: Int) {
    mpbrand(filter: $filter, pageSize: $pageSize) {
      items{
        default_value
        image
        is_display
        is_featured
        product_quantity
        short_description
        store_id
        url_key
        value
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
    brandConfig(storeId: $storeId) {
      general {
        brand_route
      }
      brands_page_settings {
      brand_filter_alphabet
      brand_filter_character_set
      brand_list_name
      brand_related_title
      display_option
      featured_brand_title
      }
    }
  }`

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sf-pagination flex items-center justify-center gap-2"},[_vm._t("prev",function(){return [_c('div',{staticClass:"sf-pagination__item prev",class:{ 'hidden': !_vm.hasArrows }},[_c(_vm.componentIs,{tag:"component",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoPrev,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.getPrev) : null,"disabled":!_vm.hasRouter && !_vm.canGoPrev ? true : false,"aria-label":"Go to previous page","data-testid":"pagination-button-prev"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getPrev)}}},[_c('svg',{staticClass:"h-4 w-4 lg:h-6 lg:w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M15.09 3.33a.742.742 0 0 1 .53.22.754.754 0 0 1 0 1.06L9.1 11.13a1.231 1.231 0 0 0 0 1.74l6.519 6.519a.75.75 0 0 1-1.059 1.06L8.037 13.93a2.724 2.724 0 0 1-.8-1.93 2.683 2.683 0 0 1 .8-1.93l6.52-6.52a.786.786 0 0 1 .533-.22Z","fill":"currentColor","data-name":"arrow-right"}})])])],1)]},null,{ isDisabled: !_vm.canGoPrev, go: _vm.go, prev: _vm.getPrev }),_vm._v(" "),[_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
          'hidden': !_vm.showFirst,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(1) : null},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(1)}}},[_vm._v("\n        1\n      ")])]},null,{ page: 1 }),_vm._v(" "),_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item",class:{ 'hidden': _vm.firstVisiblePageNumber <= 2 }},[_vm._v("\n        ...\n      ")])]})],_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return [_vm._t("number",function(){return [_c(_vm.currentPage === page ? 'span' : _vm.componentIs,{key:page,tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter && _vm.currentPage !== page,
          current: _vm.currentPage === page,
        },attrs:{"link":_vm.hasRouter && _vm.currentPage !== page ? _vm.getLinkTo(page) : null},on:{"click":function($event){!_vm.hasRouter && _vm.currentPage !== page ? _vm.go(page) : null}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])]},null,{ page: page, currentPage: _vm.currentPage })]}),_vm._v(" "),(_vm.showLast)?[_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item",class:{
          'hidden': _vm.lastVisiblePageNumber >= _vm.total - 1,
        }},[_vm._v("\n        ...\n      ")])]}),_vm._v(" "),_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.total) : null},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.total)}}},[_vm._v("\n        "+_vm._s(_vm.total)+"\n      ")])]},null,{ page: _vm.total })]:_vm._e(),_vm._v(" "),_vm._t("next",function(){return [_c('div',{staticClass:"sf-pagination__item next",class:{ 'hidden': !_vm.hasArrows }},[_c(_vm.componentIs,{tag:"component",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoNext,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.getNext) : null,"disabled":!_vm.hasRouter && !_vm.canGoNext ? true : false,"aria-label":"Go to previous next","data-testid":"pagination-button-next"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getNext)}}},[_c('svg',{staticClass:"h-4 w-4 lg:h-6 lg:w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M8.91 20.67a.742.742 0 0 1-.53-.22.754.754 0 0 1 0-1.06l6.52-6.52a1.231 1.231 0 0 0 0-1.74L8.381 4.611a.75.75 0 0 1 1.059-1.06l6.523 6.519a2.724 2.724 0 0 1 .8 1.93 2.683 2.683 0 0 1-.8 1.93l-6.52 6.52a.786.786 0 0 1-.533.22Z","fill":"currentColor","data-name":"arrow-right"}})])])],1)]},null,{ isDisabled: !_vm.canGoNext, go: _vm.go, next: _vm.getNext })],2)}
var staticRenderFns = []

export { render, staticRenderFns }
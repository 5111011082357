import { render, staticRenderFns } from "./CategoryNavbar.vue?vue&type=template&id=363d09f8&"
import script from "./CategoryNavbar.vue?vue&type=script&lang=ts&"
export * from "./CategoryNavbar.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryNavbar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/components/storyblok/Button.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isClient } from "@storefront-ui/vue/src/utilities/helpers";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfScrollable from "@storefront-ui/vue/src/components/molecules/SfScrollable/SfScrollable.vue";
import BoldChevron from "../atoms/BoldChevron/BoldChevron";
import SfChevron from "@storefront-ui/vue/src/components/atoms/SfChevron/SfChevron.vue";

export default {
  name: "BoldTab",
  components: {
    BoldChevron,
    SfScrollable,
    SfChevron,
    SfButton,
  },
  inject: ["tabConfig"],
  props: {
    title: {
      type: String,
      default: "",
    },
    contentClasses: {
      type: String,
      default: ""
    },
    headerClasses: {
      type: String,
      default: ""
    },
    useBoldChevron: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      desktopMin: 0,
    };
  },
  computed: {
    tabMaxContentHeight() {
      return this.tabConfig.tabMaxContentHeight;
    },
    tabShowText() {
      return this.tabConfig.tabShowText;
    },
    tabHideText() {
      return this.tabConfig.tabHideText;
    },
  },
  methods: {
    tabClick() {
      if (!isClient) return;
      const width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth
      );
      if (this.isActive && width > this.desktopMin) return;
      this.$parent.$emit("toggle", this._uid);
    },
  },
};
